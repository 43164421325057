<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-overlay :show="userLoading">
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Organization" vid="org_id">
              <b-form-group
                label-for="org_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('externalTradeTraiff.office')}}
              </template>
              <b-form-select
                plain
                v-model="formData.org_id"
                :options="orgList"
                id="org_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Office Type" vid="office_type_id">
              <b-form-group
                label-for="office_type_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('license_management.office_type')}}
              </template>
              <b-form-select
                plain
                v-model="formData.office_type_id"
                :options="officeTypeList"
                id="office_type_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Office" vid="office_id">
              <b-form-group
                label-for="office_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.office')}}
              </template>
              <b-form-select
                plain
                v-model="formData.office_id"
                :options="officeList"
                id="office_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Designation" vid="designation_id">
              <b-form-group
                label-for="designation_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('bazarMonitoring.designation')}}
              </template>
              <b-form-select
                plain
                v-model="formData.designation_id"
                :options="designationList"
                id="designation_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Employee Name" vid="employee_id">
              <b-form-group
                label-for="employee_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('concernManagement.employee_name')}}
              </template>
              <b-form-select
                plain
                v-model="formData.employee_id"
                :options="userList"
                id="employee_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
              <template v-slot:first>
               <b-form-select-option :value=0>{{ userLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
           </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Subject" vid="subject_id" rules="required|min_value:1">
              <b-form-group
                label-for="subject_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('concernManagement.subject')}} <span class="text-danger">*</span>
              </template>
              <b-form-select
                plain
                v-model="formData.subject_id"
                :options="subjectList"
                id="subject_id"
                :state="errors[0] ? false : (valid ? true : null)"
                disabled
              >
                <template v-slot:first>
                  <b-form-select-option :value='0'>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <template>
          <div style="font-size:18px; background-color: #17a2b8;border-radius:5px" class="p-1">
            <h5 class="text-white text-center">
              {{ $t('concernManagement.make_schedule') }}
            </h5>
          </div>
        </template>
        <br/>
        <b-row>
          <b-col xs="6" sm="6" md="6">
            <ValidationProvider name="Date" vid="date">
              <b-form-group
                label-for="date"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{ $t('globalTrans.date') }}
                </template>
                <date-picker
                   :config="config"
                    class="form-control disabled"
                    v-model="details.date"
                    id="dob"
                    placeholder="Select Date"
                    :state="errors[0] ? false : (valid ? true : null)"
                >
                </date-picker>
                <div class="d-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Start Time" vid="start_time" v-slot="{ errors }">
              <b-form-group
                label-for="start_time">
                <template v-slot:label>
                  {{ $t('concernManagement.start_time') }}
                </template>
                <b-form-input
                  id="start_time"
                  type="time"
                  v-model="details.start_time"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="End Time" vid="end_time" v-slot="{ errors }">
              <b-form-group
                label-for="end_time">
                <template v-slot:label>
                  {{ $t('concernManagement.end_time') }}
                </template>
                <b-form-input
                  id="end_time"
                  type="time"
                  v-model="details.end_time"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-3">
            <b-button @click="addItem()" type="button" variant="success" class="mr-2 mb-2 btn-sm">
              {{ $t('concernManagement.add_more') }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <!-- -----------------Add More Start------------------- -->
          <div class="table-wrapper table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <b-thead>
                <tr>
                  <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th class="text-center">{{ $t('globalTrans.date') }}</b-th>
                  <b-th class="text-center">{{ $t('concernManagement.start_time') }}</b-th>
                  <b-th class="text-center">{{ $t('concernManagement.end_time') }}</b-th>
                  <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                </tr>
              </b-thead>
              <b-tbody>
                <template v-if="formData.schedule_details && formData.schedule_details.length">
                  <b-tr v-for="(item, index) in formData.schedule_details" :key="index">
                    <b-td class="text-center">{{ index+1 }}</b-td>
                    <b-td class="text-center">{{ item.date | dateFormat }}</b-td>
                    <b-td class="text-center">{{ item.start_time | time12FormateTraining }}</b-td>
                    <b-td class="text-center">{{ item.end_time | time12FormateTraining}}</b-td>
                    <td class="text-center">
                      <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                    </td>
                  </b-tr>
                </template>
                <template v-else>
                  <b-tr>
                    <b-td colspan="5" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </table>
          </div>
          <!-- -----------------Add More End--------------------- -->
        </b-row>
        <b-row class="text-right">
          <b-col>
            <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
            <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
        </b-overlay>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { makeScheduleCreateUpdate, officeListByDesignationApi } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id', 'items', 'appointmentId'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.$t('concernManagement.create_schedule'),
      errors: [],
      formData: {
        schedule_details: []
      },
      details: {
        date: '',
        start_time: '',
        end_time: ''
      },
      fileValidationMsz: '',
      officeTypeList: [],
      designationList: [],
      officeList: [],
      userList: [],
      config: { static: true },
      userLoading: false
    }
  },
  created () {
    this.officeTypeList = this.getOfficeTypeList(4)
    if (this.id) {
      const tmp = this.getCreteOfficerId()
      this.formData = Object.assign({}, this.formData, tmp)
    }
  },
  computed: {
    subjectList: function () {
      return this.$store.state.TradeTariffService.commonObj.subjectList.filter(item => item.status === 1)
    },
    orgList () {
      const list = this.$store.state.CommonService.commonObj.orgProfileList.filter(item => item.value === 4)
        return list.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { value: item.value, text: item.text_bn }
          } else {
            return { value: item.value, text: item.text_en }
          }
        })
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
    'formData.office_type_id': function (newVal, oldVal) {
      if (newVal) {
        this.officeList = this.getOfficeList(newVal)
      } else {
        this.officeList = []
      }
    },
    'formData.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.designationList = this.getDesignationList(newVal)
      } else {
        this.designationList = []
      }
    },
    'formData.designation_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getUserList(newVal)
      }
    }
  },
  methods: {
    async addItem () {
      if (this.details.date && this.details.start_time && this.details.end_time) {
      const obj = {
        date: this.details.date,
        start_time: this.details.start_time,
        end_time: this.details.end_time
      }
      const objExist = this.formData.schedule_details.find(detail => detail.date === obj.date && detail.start_time === obj.start_time && detail.end_time === obj.end_time)
        if (typeof objExist === 'undefined') {
          this.formData.schedule_details.push(obj)
        } else {
          this.$toast.error({
            title: 'Data has already been added',
            color: '#D6E09B'
          })
        }
      }
      this.details = {
          date: '',
          start_date: '',
          end_date: ''
      }
      this.$refs.form.reset()
    },
    removeItem (item) {
      this.formData.schedule_details.splice(item, 1)
    },
    async getUserList (designationId) {
      this.userLoading = true
        const result = await RestApi.getData(authServiceBaseUrl, `${officeListByDesignationApi}/${designationId}`)
        this.userLoading = false
          if (result) {
            this.userList = result.map((obj, index) => {
              if (obj.value === this.formData.employee_id) {
                return Object.assign({}, obj, {
                  value: obj.value,
                  text: this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                })
              }
            })
          } else {
            this.userList = []
          }
    },
    getOfficeTypeList (orgId = null) {
      const officeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1)
      if (orgId) {
        return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.value, text: obj.text_bn }
          } else {
            return { value: obj.value, text: obj.text }
          }
        })
      }
      return officeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
      return officeList
    },
    getDesignationList (officeId) {
      const list = this.$store.state.CommonService.commonObj.assignDesignationList.filter(item => item.office_id === officeId && item.office_type_id === this.formData.office_type_id && item.org_id === this.formData.org_id)
      return list.map(obj => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.value, text: obj.text_bn }
        } else {
          return { value: obj.value, text: obj.text }
        }
      })
    },
    onChange (e) {
      this.fileValidationMsz = ''
      const input = event.target
      const file = input.files[0]
      if (file.size > 1024 * 2048) {
        e.preventDefault()
        this.fileValidationMsz = 'Maximum file size must be 2MB'
      }
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.formData.photo = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        this.formData.photo = ''
      }
    },
    async saveUpdate () {
      const errorForm = this.formData.schedule_details.length
      if (errorForm > 0) {
           this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            if (this.id) {
              result = await RestApi.putData(tradeTariffServiceBaseUrl, `${makeScheduleCreateUpdate}/${this.appointmentId}`, this.formData)
            }
            // else {
            //   result = await RestApi.postData(tradeTariffServiceBaseUrl, tariffProfileManagementStore, this.formData)
            // }
            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)

            if (result.success) {
              this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
              this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })

              this.$bvModal.hide('modal-form')
            } else {
              this.$refs.form.setErrors(result.errors)
            }
      } else {
        this.$toast.success({
                title: 'Error',
                message: this.$t('concernManagement.schedule_msg'),
                color: '#ee5253'
              })
      }
    },
    getCreteOfficerId () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
