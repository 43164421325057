<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <ValidationProvider name="Reason" vid="reason">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="reason"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{ $t('globalTrans.reject')}}
                </template>
                <b-form-textarea
                  id="reason"
                  v-model="formData.reason"
              >
              </b-form-textarea>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.reject') }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { tradeTariffServiceBaseUrl } from '@/config/api_config'
import { makeScheduleReject } from '../../api/routes'
export default {
  name: 'Reject',
  props: ['id'],
  data () {
    return {
      tradeTariffServiceBaseUrl: tradeTariffServiceBaseUrl,
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.forward') : this.$t('globalTrans.forward'),
      errors: [],
      formData: {
        reason: ''
      }
    }
  },
  created () {
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  watch: {
  },
  methods: {
     async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(tradeTariffServiceBaseUrl, `${makeScheduleReject}/${this.id}`, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
